import './video-uploader.scss';

import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { TextField, Tooltip } from '@mui/material';

import { AgGridReact } from 'ag-grid-react';
import { InfoOutlined } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { LoadingBar } from 'components/loading-bar/loading-bar';
import { Video } from 'graphql/gql-types';
import axios from 'axios';
import { upperFirst } from 'lodash';
import useApolloErrorAlerts from 'features/alerts/hooks/use-apollo-error-alerts';
import { useGetVideoQueueQuery } from 'features/admin/gql/_gen_/admin.gql';
import { useOktaAuth } from '@okta/okta-react';
import { useState } from 'react';

const columns: ColDef[] = [
  { field: 'title', headerName: 'Title' },
  {
    field: 'status',
    headerName: 'Status',
    cellRenderer: ({ data, value }: ICellRendererParams<Video>) => {
      const message = data?.errorMessage
        ? `Error Message: ${data.errorMessage}`
        : undefined;
      return (
        <Tooltip title={message}>
          <div className='status-cell'>
            {upperFirst(value)}
            {message && <InfoOutlined className='info-icon' />}
          </div>
        </Tooltip>
      );
    },
  },
];

export const VideoUploader = () => {
  const { authState } = useOktaAuth();
  const token = authState?.accessToken?.accessToken;
  const { data, loading, error } = useGetVideoQueueQuery();
  const [url, setUrl] = useState('');

  useApolloErrorAlerts([error]);

  const handleSubmit = async () => {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };
    await axios.post(
      `${process.env.REACT_APP_BEDROCK_API_URL}/transcribe-video`,
      null,
      {
        headers,
        params: { url: encodeURI(url) },
      }
    );
  };

  return (
    <div className='video-uploader'>
      <div className='row'>
        <Link to='/admin'>
          <button>Back</button>
        </Link>
      </div>
      <div className='row'>
        <h3>Add videos to RevBot knowledge base</h3>
      </div>
      <div className='row'>
        <TextField
          label='YouTube URL'
          value={url}
          onChange={(e) => setUrl(e.target.value)}
        />
        <button onClick={handleSubmit} className='submit' disabled={!url}>
          Submit
        </button>
      </div>
      <LoadingBar className='ag-theme-alpine' loading={loading}>
        <AgGridReact
          rowData={data?.getVideoQueue || []}
          columnDefs={columns}
          onGridReady={(e) => e.api.sizeColumnsToFit()}
          onGridSizeChanged={(e) => e.api.sizeColumnsToFit()}
          suppressCellFocus
          suppressMovableColumns
        />
      </LoadingBar>
    </div>
  );
};
